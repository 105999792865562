import {makeObservable, observable} from "mobx";
import {isJson} from "../../../admin/spinup/utilities";
import WatsonApi from "../../../../backends/WatsonApi";

class BlockObject implements IBlockObj {
    absoluteUrl: string;
    asyncLoaded: boolean;
    blockModel: OrganizationTypeOrganization;  //OrganizationTypeOrganization
    blockType: IBlockType;
    cache: boolean;
    data: any;
    errorMessage?: string;
    filterBlockType: any;
    filterCategories: string;
    filterString: string;
    filterTags: string;
    fluxDeleted?: boolean;
    fluxLocked?: boolean;
    fluxMoved?: boolean;
    fluxParentDeleted?: boolean;
    fluxResized?: boolean;
    fluxUnlocked?: boolean;
    gridHeight: number;
    gridWidth: number;
    hasSubmitTitle: boolean;
    htmlOutput?: string;
    id: string;
    isBlockInEmail: boolean;
    isDepartment: boolean;
    isModalTrigger: boolean;
    key: AnyBlockObj[];
    loadAsync: boolean;
    modalTitle: string;
    name: string;
    outputHtml?: string;
    positionLocked: boolean;
    posX: number;
    posY: number;
    renderHtml: boolean;
    renderWrapper: boolean;
    showErrors: boolean;
    sizeX: number;
    sizeY: number;
    title?: string;
    useAbsoluteUrls: boolean;
    contentSources: any;
    expanded: boolean = false;

    constructor(blockType: IBlockType, initialProps?: AnyBlockObj | IPersonBlock | undefined) {
        this.blockType = blockType;

        makeObservable(this, {
            asyncLoaded: observable,
            blockType: observable,
            contentSources: observable,
            errorMessage: observable,
            fluxDeleted: observable,
            fluxLocked: observable,
            fluxMoved: observable,
            fluxParentDeleted: observable,
            fluxResized: observable,
            fluxUnlocked: observable,
            gridHeight: observable,
            gridWidth: observable,
            id: observable,
            positionLocked: observable,
            posX: observable,
            posY: observable,
            sizeX: observable,
            sizeY: observable,
            title: observable,
            expanded: observable,
        });
    }

    setAttributes(attrs): void {
        for (const key in attrs) {
            if (key === 'blockModel' && isJson(attrs[key]?.json_data)) {
                // convert this string to JSON.
                attrs[key].json_data = JSON.parse(attrs[key].json_data);
            }
            this[key] = attrs[key];
        }
    }

    async getContentSources(blockId) {
        const client = await WatsonApi();
        return await client.organizations.organizations_content_sources_list({
            organization_pk: blockId,
        })
    }

    contentSourceToSettings(contentSource: ContentSource) {
        return {
            source_url: contentSource.source_url || '',
            language: contentSource.language || '',
        }
    }
    contentItemToSettings (contentItem: any) {}
    settingsObjectToSettings (settingsObj: IBlockSettingsObject) {}
}

export default BlockObject;
