import {reaction, observable, action, makeObservable} from "mobx";
import {getCdnUrl} from "../utils/SchoolBlocksUtilities";
import {
    color_lightness,
    lightenOrDarken,
    hexToRGB,
    RGBToHex,
    getFontColorRGB,
    getDarkerColor,
    checkContrast
} from "../utils/colorcontrast";
import {defaultFontFamily} from "../utils/Settings";
import {buildCssUrlString} from "../utils/StringUtilities";
import {ExecutionEnvironment, IView} from "./InterfaceStore";

const defaultTheme = {
    primaryNavBackground: "#3772d9",
    secondaryNavBackground: "#043385",
    blocks: "#0d3e7a",
    elements: "#103b73",
    fontFamily: defaultFontFamily,
};

// accepts hex
function getOrganizationTextColor(bgColor, blockColor = false, secondaryNavBackgroundColor = false) {
    const bgColorLightness = color_lightness(bgColor);
    const blockColorLightness = blockColor ? color_lightness(blockColor) : false;
    const secondaryNavBgColorLightness = secondaryNavBackgroundColor ? color_lightness(secondaryNavBackgroundColor) : false;
    let finalColor;
    let finalColorPassesContrast = false;
    if (bgColorLightness < 70) {
        finalColor = '#FFFFFF';
        finalColorPassesContrast = checkContrast(finalColor, bgColor);
    }
    if (!finalColorPassesContrast && blockColor && blockColorLightness < 50) {
        finalColor = blockColor;
        finalColorPassesContrast = checkContrast(finalColor, bgColor);
    }
    if (!finalColorPassesContrast && secondaryNavBackgroundColor && secondaryNavBgColorLightness < 50) {
        finalColor = secondaryNavBackgroundColor;
        finalColorPassesContrast = checkContrast(finalColor, bgColor);
    }
    if (!finalColorPassesContrast){
        finalColor = '#000000';
    }
    return finalColor;
}

function getDerivedBlocksColors(blocks) {
    const db = lightenOrDarken(blocks, -60, true, true);
    return {
        darkerBlocks: `rgb(${db[0]}, ${db[1]}, ${db[2]})`,
        darkerBlocksTextColor: getOrganizationTextColor(RGBToHex(db[0], db[1], db[2]), blocks, false),
        floatingBlockTextColorUseBlocks: getOrganizationTextColor("#FFFFFF", blocks, false), // set-organization-text-color($block-background-color, true, false)
        blocksTextColor: getOrganizationTextColor(blocks, blocks, false), // set-organization-text-color($organizationColor-Blocks, true, false);
    }
}

function getDerivedPrimaryNavFontColors(primaryNavFontColor, blocks) {
    return {
        lighterPrimaryNavFontColor10: lightenOrDarken(primaryNavFontColor, 10),
        primaryNavFontColorTextColor: getOrganizationTextColor(primaryNavFontColor, blocks, false), // set-organization-text-color($organizationColor-PrimaryNavFontColor, true, false);
    }
}

export function getBackgroundImageValue(backgroundImage) {
    if (/^\/_next\/static/.test(backgroundImage)) {
        return {backgroundImage: buildCssUrlString(backgroundImage)}
    } else if (/url\(.*\)/.test(backgroundImage)) { // if it includes url, we assume it's complete
        return {backgroundImage}
    } else if (/http|^\/assets|^data:image/.test(backgroundImage)) { // if it doesn't have url but has http or coming from our assets, we assume it just needs url()
        return {backgroundImage: buildCssUrlString(backgroundImage)}
    } else { // otherwise we assume it's just the path
        return {backgroundImage: buildCssUrlString(getCdnUrl(backgroundImage))}
    }
}

function getDerivedBackgroundColorValues(backgroundColor) {
    const backgroundColorRGB = hexToRGB(backgroundColor);
    return {backgroundColor90Opacity: `rgba(${backgroundColorRGB[0]},${backgroundColorRGB[1]},${backgroundColorRGB[2]},0.90)`}
}

function getDerivedElementsColors(elements) {
    const darkerElements10RGB = lightenOrDarken(elements, -10, true, true);
    return {
        darkerElements10: `rgb(${darkerElements10RGB[0]},${darkerElements10RGB[1]},${darkerElements10RGB[2]})`,
        darkerElements10TextColor: getOrganizationTextColor(RGBToHex(darkerElements10RGB[0],darkerElements10RGB[1],darkerElements10RGB[2]), false, false), // set-organization-text-color($darkerBgColor, false, false);
        elementsTextColorNoBlocks: getOrganizationTextColor(elements, false, false), // set-organization-text-color($organizationColor-Elements, false, false);
        elementsLightness: color_lightness(elements),
    }
}

function getPrimaryAndSecondaryColors(primary, secondary, themeID, blocks) {
    if (parseInt(themeID) === 2 || parseInt(themeID) === 3) {
        secondary = primary;
    }

    const primaryNavBackgroundRGB = hexToRGB(primary);
    const secondaryNavBackgroundRGB = hexToRGB(secondary);
    const blockBgTextColorNoBlocksUseSecondaryRGB = hexToRGB(getOrganizationTextColor("#FFFFFF", false, secondary));
    const darkerBgColor = getDarkerColor(primary, secondary);
    const primaryNavBackgroundTextColor = getOrganizationTextColor(primary, blocks, false); // set-organization-text-color($organizationColor-PrimaryNavBackground, true, false);

    return {
        darkerBgColor,
        darkerBgColor5: lightenOrDarken(darkerBgColor, -5),
        darkerBgColor10: lightenOrDarken(darkerBgColor, -10),
        primaryFontColor: getFontColorRGB(primary),
        primaryNavBackgroundTextColor,
        primaryNavBackgroundTextColorNoBlocksUseSecondary: getOrganizationTextColor(primary, false, secondary), // set-organization-text-color($organizationColor-PrimaryNavBackground, false, true);
        secondaryNavBackgroundTextColor: getOrganizationTextColor(secondary, blocks, false), // set-organization-text-color($organizationColor-SecondaryNavBackground, true, false);
        secondaryNavBackground: secondary,
        lightSecondaryNavBackgroundRGB: `rgba(${ secondaryNavBackgroundRGB[0] + (0.6 * (255-secondaryNavBackgroundRGB[0])) },${secondaryNavBackgroundRGB[1] + (0.6 * (255-secondaryNavBackgroundRGB[1]))},${secondaryNavBackgroundRGB[2] + (0.6 * (255-secondaryNavBackgroundRGB[2]))},1)`,
        opaqueSecondaryNavBackgroundRGB: `rgba(${ secondaryNavBackgroundRGB[0] + (0.9 * (255-secondaryNavBackgroundRGB[0])) },${secondaryNavBackgroundRGB[1] + (0.9 * (255-secondaryNavBackgroundRGB[1]))},${secondaryNavBackgroundRGB[2] + (0.9 * (255-secondaryNavBackgroundRGB[2]))},0.75)`,
        opaquePrimaryNavBackground: `rgba(${primaryNavBackgroundRGB[0]},${primaryNavBackgroundRGB[1]},${primaryNavBackgroundRGB[2]},0.95)`,
        blockBgTextColorNoBlocksUseSecondary: getOrganizationTextColor("#FFFFFF", false, secondary), // set-organization-text-color(#ffffff, false, true)
        blockBgTextColorNoBlocksUseSecondaryRGBLight: `rgba(${ blockBgTextColorNoBlocksUseSecondaryRGB[0] + (0.6 * (255-blockBgTextColorNoBlocksUseSecondaryRGB[0])) },${blockBgTextColorNoBlocksUseSecondaryRGB[1] + (0.6 * (255-blockBgTextColorNoBlocksUseSecondaryRGB[1]))},${blockBgTextColorNoBlocksUseSecondaryRGB[2] + (0.6 * (255-blockBgTextColorNoBlocksUseSecondaryRGB[2]))},1)`,
    }
}

function setCssVar(name, value) {
    const r = document.querySelector(':root');
    if (value) {
        r.style.setProperty(`--${name}`, value);
    } else {
        r.style.removeProperty(`--${name}`);
    }
}

export function shouldShowBackgroundImage (view) {
    const inValidViews = [IView.MAP, IView.ALPHA];

    return (
        !inValidViews.includes(view)
    );
}

export default class StyleStore {
    themeID = null;
    cssVars = null;

    constructor(interfaceStore, organizationStore) {
        makeObservable(this, {
            themeID: observable,
            setThemeID: action,
            cssVars: observable,
        });

        // only setup a reaction on client to avoid memory leaks
        if (ExecutionEnvironment.canUseDOM) {
            reaction(() => [JSON.stringify(organizationStore.organization?.json_data?.settings), organizationStore.currentOrganization.backgroundImage, organizationStore.currentOrganization?.json_data?.terracedGrid?.enabled], () => {
                this.updateStyles(organizationStore.organization?.json_data?.settings, organizationStore.currentOrganization, interfaceStore);
            });
        }
    }

    updateStyles = (organizationSettings, currentOrganization, interfaceStore) => {
        this.appearance = organizationSettings?.appearance;
        this.setThemeID(this.appearance?.themeID);
        this.theme = this.appearance?.themes?.[this.themeID] || {};

        let updateObj = {...this.theme};

        updateObj.blockBgTextColorUseBlocks = getOrganizationTextColor("#FFFFFF");
        updateObj.fontColor = organizationSettings?.alert?.fontColor;
        updateObj.backgroundColor = organizationSettings?.alert?.backgroundColor;

        if (this.theme.fontFamily) {
            updateObj.fontFamily = this.theme.fontFamily;
        } else {
            updateObj.fontFamily = defaultTheme.fontFamily;
        }
        if (this.theme.primaryNavFontColor) {
            updateObj = {...updateObj, ...getDerivedPrimaryNavFontColors(this.theme.primaryNavFontColor, updateObj.blocks)};
        }
        if (this.theme.blocks) {
            updateObj = {...updateObj, ...getDerivedBlocksColors(this.theme.blocks)};
        }
        if (updateObj.backgroundColor) {
            updateObj = {...updateObj, ...getDerivedBackgroundColorValues(updateObj.backgroundColor)};
        }
        if (this.theme.elements) {
            updateObj = {...updateObj, ...getDerivedElementsColors(this.theme.elements)};
        }
        if (this.theme.primaryNavBackground) {
            updateObj = {...updateObj, ...getPrimaryAndSecondaryColors(this.theme.primaryNavBackground, this.theme.secondaryNavBackground, this.themeID, updateObj.blocks)};
        }

        this.setCssVars(updateObj);
        if (ExecutionEnvironment.canUseDOM && shouldShowBackgroundImage(interfaceStore.view)) {
            setCssVar('backgroundImage', currentOrganization?.backgroundImage ? getBackgroundImageValue(currentOrganization.backgroundImage).backgroundImage : undefined);
        }
    }

    setThemeID(id) {
        this.themeID = `${id}`; // guarantees themeID is always a string
    }

    setCssVars(themeObj) {
        this.cssVars = themeObj;

        if (ExecutionEnvironment.canUseDOM) {
            // explicitly only include link stylesheets from this repo. without this css-vars creates errors
            // from resources like google fonts, see https://github.com/jhildenbiddle/css-vars-ponyfill/issues/86
            for (let k in this.cssVars) {
                setCssVar(k, this.cssVars[k]);
            }
        }
    }
}
