import {makeObservable, observable, action} from "mobx";
import {INotification} from "./Notification";

export const createUUID = () => {
    const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return pattern.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
};

export interface INotify extends INotification {
    id: string,
}

const Constants = {
    CHANGE: 'change',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
};

class NotificationManager {
    listNotify: INotify[] = [];

    constructor() {
        makeObservable(this, {
            listNotify: observable,
            create: action,
            remove: action,
        })
    }

    create(notify) {
        const defaultNotify = {
            id: createUUID(),
            type: 'info',
            title: null,
            message: null,
            timeOut: 1500
        };
        if (notify.priority) {
            this.listNotify.unshift(Object.assign(defaultNotify, notify));
        } else {
            this.listNotify.push(Object.assign(defaultNotify, notify));
        }
    }

    info(
        message: INotification["message"],
        title?: INotification["title"],
        timeOut?: INotification["timeOut"],
        onClick?: INotification["onClick"],
        priority?: boolean
    ) {
        this.create({
            type: Constants.INFO,
            message,
            title,
            timeOut,
            onClick,
            priority
        });
    }

    success(
        message: INotification["message"],
        title?: INotification["title"],
        timeOut?: INotification["timeOut"],
        onClick?: INotification["onClick"],
        priority?: boolean
    ) {
        this.create({
            type: Constants.SUCCESS,
            message,
            title,
            timeOut,
            onClick,
            priority
        });
    }

    warning(
        message: INotification["message"],
        title?: INotification["title"],
        timeOut?: INotification["timeOut"],
        onClick?: INotification["onClick"],
        priority?: boolean
    ) {
        this.create({
            type: Constants.WARNING,
            message,
            title,
            timeOut,
            onClick,
            priority
        });
    }

    error(
        message: INotification["message"],
        title?: INotification["title"],
        timeOut?: INotification["timeOut"],
        onClick?: INotification["onClick"],
        priority?: boolean
    ) {
        this.create({
            type: Constants.ERROR,
            message,
            title,
            timeOut,
            onClick,
            priority
        });
    }

    remove(notification) {
        this.listNotify = this.listNotify.filter((n) => notification.id !== n.id);
    }
}

export default new NotificationManager();
