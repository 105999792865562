import {AppProps} from 'next/app';
import {useRouter} from 'next/router';

import getAllStores from "../utils/useAllStores";
import {StoreContext} from "../stores/StoreLoader";
import {withLocaleInURI} from "../internationalization/i18nURI";
import {IView} from "../stores/InterfaceStore";
import {useMemo} from "react";

export default function SoprisApps({Component, pageProps}: AppProps) {
    const allStores = useMemo(getAllStores, []);
    const router = useRouter();

    // We need to set the currentFullUrl on the client side now in order to respond to shallow routes (when
    // getServerSideProps isn't called).  This is a good thing.
    // The logic around what locale we're putting in the URL might not be right though.  We need to double check
    // the value that NextJS is using in router.local with what we should be setting/using in i18nStore.
    pageProps.currentFullUrl = withLocaleInURI(
        pageProps.currentBaseUrl ? pageProps.currentBaseUrl + router.asPath : router.asPath,
        router.locale
    );
    // Let's always prefer the next/router locale instead of whatever may or may not have been returned from
    // getServerSideProps().  This also ensures that shallow route changes in the language tool work properly.
    pageProps.locale = router.locale;

    const previousView = allStores.interfaceStore.view;

    allStores.i18nStore.setLocaleByUrl(allStores.i18nStore.getLocaleRecord(router.locale).locale);
    allStores.interfaceStore.setCurrentFullUrl(pageProps.currentFullUrl);
    allStores.interfaceStore.closeAllMenus();
    allStores.interfaceStore.setUserAgent(pageProps.userAgent);
    allStores.interfaceStore.setView(pageProps.view);

    if (pageProps.organization &&
        pageProps.organization.id !== allStores.organizationStore.organization.id) {
        allStores.organizationStore.buildOrganization(pageProps.organization);
        allStores.styleStore.updateStyles(pageProps.organization.json_data?.settings, pageProps.currentOrganization, allStores.interfaceStore);
        allStores.i18nStore.setOrgDefaultLocale(pageProps.organization.json_data?.settings?.languages?.sourceLanguage);
        allStores.i18nStore.setOrganizationSupportedLocales(pageProps.organization.json_data?.settings?.languages?.targetLanguages);
    }

    const orgIdHasChanged = pageProps.currentOrganization &&
        pageProps.currentOrganization.id !== allStores.organizationStore.currentOrganization.id

    // perform these actions on page transitions and initial load, but not on query parameter changes
    if (orgIdHasChanged || ((previousView && pageProps.view) ? previousView !== pageProps.view : false)) {
        if (pageProps.currentOrganization && pageProps.currentOrganization.user_permissions) {
            allStores.userStore.setPermissions(pageProps.currentOrganization.user_permissions);
        }
        allStores.modalStore.clearAllModals(); // clear modals so we remove any open modals before routing
        // sometimes we need data from an updated currentOrganization even when only the view has changed (i.e. schoolfeed)
        allStores.organizationStore.setCurrentOrganization(pageProps.currentOrganization);
        if (allStores.interfaceStore.schoolFeedWidgetOpen) allStores.interfaceStore.toggleSchoolFeedWidgetOpen();

        if (pageProps.blocks) {
            allStores.gridStore.setBlocks(pageProps.blocks, true, pageProps.view === IView.ALPHA);
        }
    }

    /*
    When there is a locale in the URL, we can run into the situation where the i18nStore is instantiated, meaning
    googleCode is computed, but this method (updateApp) has not yet run. So when language logic is first run, there
    is no locale defined by URL which can result in issues. This ensures that we don't run any language logic until
    after we know we have the full context of the page.
     */
    allStores.i18nStore.setContextIsUpdated(true);

    console.debug('_app render page: ', pageProps.currentFullUrl);

    return <StoreContext.Provider value={allStores}>
        <Component {...pageProps} />
    </StoreContext.Provider>
}
