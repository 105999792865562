export const enum NormalizedVideoVendor {
    YOUTUBE = "youtube",
    VIMEO = "vimeo",
    SCHOOLTUBE = "schooltube",
    MYVRSPOT = "myvrspot",
    TECHSMITHRELAY = "techsmithrelay",
    DRIVEGOOGLE = "googledrive",
    FACEBOOK = "facebook",
    THECUBE = "the cube",
}

export class NormalizedVideo {
    public originalObject = null;
    public id = null;
    public title = null;
    public description = null;
    public viewLink: string | null = null;
    public embedLink: string | null = null;
    public embedType: string | null = null; // can be either html5 or iframe
    public poster: string | null = null;
    public vendor: NormalizedVideoVendor | null = null; // can be any of the vendors we use
}
