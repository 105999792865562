export const enum sourceTypesEnum {
    ERROR = "ERROR",
    FACEBOOK = "FACEBOOK",
    TWITTER = "TWITTER",
    INSTAGRAM = "INSTAGRAM",
    ICAL = "ICAL",
    PINTEREST = "PINTEREST",
    YELP = "YELP",
    YOUTUBE = "YOUTUBE",
    VIMEO = "VIMEO",
    RSS = "RSS",
    GOOGLE_PLACES = "GOOGLE_PLACES",
    GOOGLE_DRIVE = "GOOGLE_DRIVE",
    CUSTOM = "CUSTOM",
    O365 = "O365",
    GOOGLE_CLASSROOM = "GOOGLE_CLASSROOM",
    SERP_API = "SERP_API",
}

export interface sourceType {
    type: sourceTypesEnum,
    title: string,
    feed_name: string,
    placeholder_url: string | boolean,
    items_name: string,
    icons: {
        fontawesome: string,
        flaticon: string,
    },
    blocks: string[],
    phone?: boolean,
}


class SourceUtils {
    public sourceTypes: sourceType[];
    public blockTypes;
    public sourceTitles;
    public sourceFlaticons;
    public sourceFontawesomeIcons;
    public socialSourceTypes;
    public sourceFeedNames;

    constructor() {
        // this holds all the default data for each source
        this.sourceTypes = [
            {
                type: sourceTypesEnum.ERROR,
                title: 'Error',
                feed_name: 'Errored Source',
                placeholder_url: false,
                items_name: 'errored items',
                icons: {
                    fontawesome: "fas fa-exclamation-triangle",
                    flaticon: "flaticon-error-1",
                },
                blocks: [],
            },
            {
                type: sourceTypesEnum.FACEBOOK,
                title: 'Facebook',
                feed_name: 'Facebook Feed',
                placeholder_url: 'facebook.com/example',
                items_name: 'facebook items',
                icons: {
                    fontawesome: "fab fa-facebook",
                    flaticon: "flaticon-facebook",
                },
                blocks: ['news', 'gallery', 'map'],
            },
            {
                type: sourceTypesEnum.TWITTER,
                title: 'Twitter',
                feed_name: 'Twitter Feed',
                placeholder_url: 'twitter.com/example',
                items_name: 'tweets',
                icons: {
                    fontawesome: "fab fa-x-twitter",
                    flaticon: "flaticon-twitter-1",
                },
                blocks: ['news', 'gallery'],
            },
            {
                type: sourceTypesEnum.INSTAGRAM,
                title: 'Instagram',
                feed_name: 'Instagram Feed',
                placeholder_url: 'instagram.com/example',
                items_name: 'instagram photos/videos',
                icons: {
                    fontawesome: "fab fa-instagram",
                    flaticon: "flaticon-instagram-logo",
                },
                blocks: ['gallery'],
            },
            {
                type: sourceTypesEnum.ICAL,
                title: 'Calendar',
                feed_name: 'Calendar Feed',
                placeholder_url: "enter a public ics url",
                items_name: 'events',
                icons: {
                    fontawesome: "fas fa-calendar-alt",
                    flaticon: "flaticon-calendar-2",
                },
                blocks: ['events'],
            },
            {
                type: sourceTypesEnum.PINTEREST,
                title: 'Pinterest',
                feed_name: 'Pinterest Feed',
                placeholder_url: "pinterest.com/example",
                items_name: 'pins',
                icons: {
                    fontawesome: "fab fa-pinterest",
                    flaticon: "flaticon-pinterest-logo",
                },
                blocks: ['gallery'],
            },
            {
                type: sourceTypesEnum.YELP,
                title: 'Yelp',
                feed_name: 'Yelp Feed',
                phone: true,
                placeholder_url: false,
                items_name: 'reviews',
                icons: {
                    fontawesome: "fab fa-yelp",
                    flaticon: 'flaticon-yelp-1',
                },
                blocks: ['reviews', 'map'],
            },
            {
                type: sourceTypesEnum.YOUTUBE,
                title: 'Youtube',
                feed_name: 'Youtube Feed',
                placeholder_url: "youtube.com/user/example",
                items_name: 'youtube videos',
                icons: {
                    fontawesome: "fab fa-youtube",
                    flaticon: "flaticon-youtube",
                },
                blocks: ['gallery'],
            },
            {
                type: sourceTypesEnum.VIMEO,
                title: 'Vimeo',
                feed_name: 'Vimeo Feed',
                placeholder_url: "vimeo.com/example",
                items_name: 'vimeo videos',
                icons: {
                    fontawesome: "fab fa-vimeo-v",
                    flaticon: "flaticon-vimeo-square-logo",
                },
                blocks: ['gallery'],
            },
            {
                type: sourceTypesEnum.RSS,
                title: 'RSS',
                feed_name: 'RSS Feed',
                placeholder_url: 'enter a public rss url',
                items_name: 'rss feed items',
                icons: {
                    fontawesome: "fas fa-rss-square",
                    flaticon: "flaticon-rss-feed-button",
                },
                blocks: ['news'],
            },
            {
                type: sourceTypesEnum.GOOGLE_PLACES,
                title: 'Google',
                feed_name: 'Google Source',
                phone: true,
                placeholder_url: false,
                items_name: 'google reviews',
                icons: {
                    fontawesome: "fab fa-google",
                    flaticon: 'flaticon-google-2',
                },
                blocks: ['reviews', 'map'],
            },
            {
                type: sourceTypesEnum.GOOGLE_DRIVE,
                title: 'Google Drive',
                feed_name: 'Google Drive Source',
                phone: false,
                placeholder_url: 'Enter a public file/folder url',
                items_name: 'google drive files and folders',
                icons: {
                    fontawesome: 'fab fa-google-drive',
                    flaticon: 'flaticon-google-drive-logo',
                },
                blocks: ['files_folders'],
            },
            {
                type: sourceTypesEnum.O365,
                title: 'Office 365',
                feed_name: 'Office 365 Source',
                phone: false,
                placeholder_url: 'Enter a public file/folder url',
                items_name: 'office 365 files and folders',
                icons: {
                    fontawesome: 'fab fa-microsoft',
                    flaticon: 'flaticon-microsoft',
                },
                blocks: ['files_folders'],
            },
            {
                type: sourceTypesEnum.CUSTOM,
                title: 'Custom',
                feed_name: 'Custom Feed',
                placeholder_url: '',
                items_name: 'custom items',
                icons: {
                    fontawesome: '',
                    flaticon: '',
                },
                blocks: [],
            },
            {
                type: sourceTypesEnum.GOOGLE_CLASSROOM,
                title: 'Google Classroom',
                feed_name: 'Google Classroom Source',
                placeholder_url: '',
                items_name: 'coursework',
                icons: {
                    fontawesome: 'fas fa-chalkboard-teacher"',
                    flaticon: 'flaticon-google-classroom-logo',
                },
                blocks: [],
            },
            {
                type: sourceTypesEnum.SERP_API,
                title: '',
                feed_name: 'News Feed',
                placeholder_url: '',
                items_name: 'news',
                icons: {
                    fontawesome: 'fas fa-newspaper"',
                    flaticon: 'flaticon-newspaper',
                },
                blocks: [],
            },
        ];

        this.blockTypes = {};
        this.sourceTitles = {};
        this.sourceFlaticons = {};
        this.sourceFontawesomeIcons = {};
        this.sourceFeedNames = {};
        this.socialSourceTypes = [];

        this.sourceTypes.forEach(source => {

            source.blocks.forEach(block => {
                if (this.blockTypes[block]) {
                    this.blockTypes[block].push(source.type);
                } else {
                    this.blockTypes[block] = [source.type];
                }
            });

            this.sourceTitles[source.type] = source.title;
            this.sourceFlaticons[source.type] = source.icons.flaticon;
            this.sourceFontawesomeIcons[source.type] = source.icons.fontawesome;
            this.sourceFeedNames[source.type] = source.feed_name;

            if (source.type !== sourceTypesEnum.ERROR && source.type !== sourceTypesEnum.CUSTOM) {
                this.socialSourceTypes.push(source.type);
            }
        });
    }
}

const sourceUtils = new SourceUtils();

export const sourceTypes = sourceUtils.sourceTypes;

export const sourceFlaticons = sourceUtils.sourceFlaticons;
export const sourceFontawesomeIcons = sourceUtils.sourceFontawesomeIcons;
export const sourceTitles = sourceUtils.sourceTitles;
export const blockTypes = sourceUtils.blockTypes;
export const socialSourceTypes = sourceUtils.socialSourceTypes;
export const sourceFeedNames = sourceUtils.sourceFeedNames;
